import { useSelector } from 'react-redux';
import './PartnersPage.scss';
import { formatNumber } from '../../helpers';

const PartnersPage = (props) => {

  const betsData = useSelector((state) => state.app.betsData);
  const winsData = useSelector((state) => state.app.winsData);
  const freespinsData = useSelector((state) => state.app.freespinsData);
  const partners = useSelector((state) => state.app.partners);
  
  return (
    <div className="PartnersPage">
      <table>
        <thead>
          <tr>
            <th>Game ID</th>
            <th>Name</th>
            <th>Total Bets</th>
            <th>Total Wins</th>
            <th>Revenue</th>
            <th>GGR %</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          {
            partners.map((partner, i) => {
              let totalBets = betsData.filter((b) => b.partner_id === partner.id).reduce((p, c) => p + c.amount, 0);
              totalBets += freespinsData.filter((f) => f.partner_id === partner.id && f.bet_id === null).reduce((p, c) => p + (c.bet_amount * 50), 0);
              const totalWins = winsData.filter((b) => b.partner_id === partner.id).reduce((p, c) => p + (c.amount_after_gamble !== null ? c.amount_after_gamble : c.amount), 0);

              return (
                <tr
                  key={i}
                >
                  <td>{partner.id}</td>
                  <td>{partner.name}</td>
                  <td>{formatNumber(totalBets)}&nbsp;$</td>
                  <td>{formatNumber(totalWins)}&nbsp;$</td>
                  <td>{formatNumber(totalBets - totalWins)}&nbsp;$</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default PartnersPage;
